<template>
  <div>
    <b-tabs card pills v-model="tabIndex">
      <b-tab title="원부매칭 관리">
        <ep-ids v-if="loaded[0]"></ep-ids>
      </b-tab>
      <b-tab title="네이버 카테고리 매칭">
        <ep-category-matching v-if="loaded[1]"></ep-category-matching>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import EpIds from './EpIds.vue';
import EpCategoryMatching from './EpCategoryMatching.vue'

export default {
  name: 'EpDataMatching',
  components: {EpIds, EpCategoryMatching},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    };
  },
  created() {
    this.loaded[0] = true;
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
};
</script>
