<template>
  <div>
    <b-modal size="xl" v-model="modal.detailImg" ok-only ok-title="닫기">
      <template v-slot:modal-title>
        <b-badge class="ml-1" variant="warning">{{item.brand_nm}} ({{item.brand_nm_kr}})</b-badge>
        <b-badge class="ml-1" variant="light">{{item.cate}}</b-badge>
        <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.category)"><i class="fa fa-copy"></i></b-badge>
        <b-badge class="ml-1 pointer" variant="info" @click="copyText(item.goods_no)">{{item.goods_no}} <i class="fa fa-copy ml-1"></i></b-badge>
        <br/>
      </template>
      <small>설명 (본문의 이미지는 편의상 300px 로 제한됨, 실제로는 꽉차게 보임)</small><br/>
      <div v-for="img in item.img_urls" class="mh-600">
        <b-img
            fluid
            :src="img"
            alt="goods images">
          @click=""
        </b-img>
      </div>
    </b-modal>

    <b-modal size="xl" v-model="modal.imgUpload" :ok-disabled="busy.upload || busy.uploadTemp" ok-title="저장" cancel-title="닫기" @hide="hideModal">
      <template v-slot:modal-title>
        <div>
          <b-badge class="pointer" variant="success" @click="copyText(item.id)"><i class="fa fa-copy"></i>{{item.id}}</b-badge>
        </div>
      </template>
      <b-row class="mb-3">
        <b-col cols="6" class="mb-2">
          <strong>임시 이미지</strong><span class="ml-1 text-danger">*</span>
        </b-col>
        <b-col cols="6" class="mb-2">
          <strong>최종 이미지</strong>
        </b-col>
        <b-col cols="6">
          <div class="d-flex flex-row flex-wrap" @drop.prevent.stop="(e) => handleTempFile(e)" @dragover="handleDragover" @dragenter="handleDragover">
            <draggable class="d-flex flex-row flex-wrap" v-model="item.temp_images" @start="drag.image=true" @end="drag.image=false">
              <template v-for="(img, idx) in item.temp_images">
                <div class="text-primary d-flex align-items-center justify-content-center position-relative mr-3 mb-3"
                     style="width:120px; height:120px; border-radius: 6px; border: solid 1px #ced4da; background-image: url(/img/transparent_bg.png)">
                  <a :href="'https://i.balaan.io/' + img.square_webp" target="_blank"><img :src="'https://i.balaan.io/' + img.square_webp" style="max-width:118px; max-height:118px;" /></a>
                  <!--                  <b-button class="position-absolute" style="top:5px; right:35px;" variant="primary" size="sm" @click="copyPicTag(img)"><i class="fa fa-copy"></i></b-button>-->
                  <b-button v-if="$R('SKU_INHOUSE')" class="position-absolute" style="top:5px; right:5px;" variant="danger" size="sm" @click="item.temp_images.splice(idx, 1)"><i class="fa fa-close"></i></b-button>
                </div>
              </template>
            </draggable>
            <div v-if="$R('SKU_INHOUSE')" class="pointer text-secondary d-flex align-items-center justify-content-center mr-3 mb-3" style="width:120px; height:120px; border-radius: 6px; border: dashed 1px #5b73e8;" @click="_=>{$refs.temp_file.value = null;$refs.temp_file.click()}">
              <i class="fa fa-plus fa-5x" v-if="!busy.uploadTemp"></i>
              <b-spinner v-if="busy.uploadTemp" style="width: 4rem; height: 4rem;"></b-spinner>
            </div>
          </div>
          <input type="file" ref="temp_file" style="display: none" @change="(e) => handleTempFile(e)" multiple :disabled="busy.uploadTemp">
        </b-col>
        <b-col cols="6">
          <div class="d-flex flex-row flex-wrap" @drop.prevent.stop="(e) => handleFile(e)" @dragover="handleDragover" @dragenter="handleDragover">
            <draggable class="d-flex flex-row flex-wrap" v-model="item.images" @start="drag.image=true" @end="drag.image=false">
              <template v-for="(img, idx) in item.images">
                <div class="text-primary d-flex align-items-center justify-content-center position-relative mr-3 mb-3"
                     style="width:120px; height:120px; border-radius: 6px; border: solid 1px #ced4da; background-image: url(/img/transparent_bg.png)">
                  <a :href="'https://i.balaan.io/' + img.square_webp" target="_blank"><img :src="'https://i.balaan.io/' + img.square_webp" style="max-width:118px; max-height:118px;" /></a>
                  <b-button v-if="(/^...002/.test(item.category) && idx === 1) || (!/^...002/.test(item.category) && idx === 0)" class="position-absolute" style="top:5px; left:5px; cursor:unset;" variant="primary" size="sm">EP</b-button>
                  <b-button class="position-absolute" style="top:5px; right:5px;" variant="danger" size="sm" @click="item.images.splice(idx, 1)"><i class="fa fa-close"></i></b-button>
                </div>
              </template>
            </draggable>
            <div class="pointer text-secondary d-flex align-items-center justify-content-center mr-3 mb-3" style="width:120px; height:120px; border-radius: 6px; border: dashed 1px #5b73e8;" @click="_=>{$refs.file.value = null;$refs.file.click()}">
              <i class="fa fa-plus fa-5x" v-if="!busy.upload"></i>
              <b-spinner v-if="busy.upload" style="width: 4rem; height: 4rem;"></b-spinner>
            </div>
          </div>
          <input type="file" ref="file" style="display: none" @change="(e) => handleFile(e)" multiple :disabled="busy.upload">
        </b-col>
      </b-row>
    </b-modal>

    <b-modal size="md" v-model="modal.imgUploadCf" :ok-disabled="busy.upload" ok-title="저장" cancel-title="닫기" @hide="hideEpUploadModal">
      <template v-slot:modal-title>
        <div>
          <b-badge class="pointer" variant="info" @click="copyText(item.goods_no)"><i class="fa fa-copy"></i>{{item.goods_no}}</b-badge>
        </div>
      </template>
      <div>
        <strong>EP 이미지</strong><span class="ml-1 text-danger">*</span>
      </div>
      <div class="d-flex flex-row flex-wrap" @drop.prevent.stop="(e) => handleEpFile(e)" @dragover="handleDragover" @dragenter="handleDragover">
        <template v-if="item.ep_thumbnail_img">
          <div class="text-primary d-flex align-items-center justify-content-center position-relative mr-3 mb-3"
               style="width:120px; height:120px; border-radius: 6px; border: solid 1px #ced4da; background-image: url(/img/transparent_bg.png)">
            <a :href="item.ep_thumbnail_img.replace('.jpg', '_square.jpg')" target="_blank"><img :src="item.ep_thumbnail_img.replace('.jpg', '_square.jpg')" style="max-width:118px; max-height:118px;" /></a>
            <b-button class="position-absolute" style="top:5px; right:5px;" variant="danger" size="sm" @click="item.ep_thumbnail_img = null">
              <i class="fa fa-close"></i>
            </b-button>
          </div>
        </template>

        <div v-else class="pointer text-secondary d-flex align-items-center justify-content-center mr-3 mb-3" style="width:120px; height:120px; border-radius: 6px; border: dashed 1px #5b73e8;" @click="_=>{$refs.epFile.value = null;$refs.epFile.click()}">
          <i class="fa fa-plus fa-5x" v-if="!busy.upload"></i>
          <b-spinner v-if="busy.upload" style="width: 4rem; height: 4rem;"></b-spinner>
        </div>
      </div>
      <input type="file" ref="epFile" style="display: none" @change="(e) => handleEpFile(e)" multiple :disabled="busy.upload">
    </b-modal>
  </div>
</template>

<script>
import * as utils from '@/shared/utils'

export default {
  name: "EpImageModal",
  props: ['modal'],
  data() {
    return {
      utils,
      item: {},
      item_org: {},
      itemAttr: {},
      busy: {uploadTemp: false, upload: false},
      epImageAsis: null,
      epImage: null
    };
  },
  computed:{
  },
  methods: {
    showModal(row, type) {
      this.item = row;
      this.item_org = this.$utils.clone(row)
      // 상품에 따라 변경될 수 있도록 - 수정필요

      this.modal[type] = true;
    },
    async hideModal(e) {
      const CHECK_COL = ('images,temp_images').split(',');
      const modifiedCols = CHECK_COL.filter(e=>JSON.stringify(this.item_org[e]) !== JSON.stringify(this.item[e]));
      if (!modifiedCols.length) {
        return;
      }

      if (['cancel', 'headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
        // 닫기
        if (!confirm('저장하지 않은 데이터는 사라집니다. 취소하시겠습니까?')) {
          e.preventDefault && e.preventDefault();
          return;
        }
      } else if (e.trigger === 'ok') {  // 마스터 저장
        const title = '마스터 수정 에러';

        if (!this.item.id) {
          this.$modal.show({title, html: '저장할 수 있는 마스터가 없습니다.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        if (this.item.processing_status === 'registered' && this.item.display_status === 'view' && !this.item.images.length) {
          this.$modal.show({title, html: '검수 후 노출 단계에서는 최종이미지가 있어야 합니다.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        if (!this.item.images.length && !this.item.temp_images.length) {
          this.$modal.show({title, html: '최소 한 장의 이미지(임시, 최종 무관)를 업로드해 주세요.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // EP 이미지 설정
        if (this.item.images.length) {
          const isClothes = /^...002/.test(this.item.category);
          this.item.ep_image = isClothes ? this.item.images[1] : this.item.images[0];
          if (!this.item.ep_image) {
            if (!isClothes) {
              this.$modal.show({title, html: 'EP 이미지가 설정되지 않았습니다. IT팀에 문의해 주세요.'});
              e.preventDefault && e.preventDefault();
              return;
            }
            this.item.ep_image = null;
          }
        } else {
          this.item.ep_image = null;
        }

        const {id, temp_images, images} = this.item;
        // 마스터 저장
        const res = await this.$api.postJson('/master/saveMasterImage', {id, temp_images, images});

        if (res) {
          if (this.item.id) {
            this.$alertTop(`마스터 ${this.item.id}가 수정되었습니다.`);
            this.$emit('save');
          }
          // 이미지 등록 트래킹
          if (!this.item_org.images.length && this.item.images.length) {
            this.$api.sendBeacon('/master/track/uploadImages', {
              gm_id: res.id,
              category: this.item.category,
            });
          }
        }
      }
    },
    async hideEpUploadModal(e) {
      const CHECK_COL = ('ep_thumbnail_img,ep_thumbnail_org').split(',');
      const modifiedCols = CHECK_COL.filter(e=>JSON.stringify(this.item_org[e]) !== JSON.stringify(this.item[e]));

      if (!modifiedCols.length) {
        return;
      }
      if (['cancel', 'headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
        // 닫기
        if (!confirm('저장하지 않은 데이터는 사라집니다. 취소하시겠습니까?')) {
          e.preventDefault && e.preventDefault();
          return;
        } else {
          this.item.ep_thumbnail_img = this.item_org.ep_thumbnail_img;
          this.item.ep_thumbnail_org = this.item_org.ep_thumbnail_org;
          return;
        }
      } else if (e.trigger === 'ok') {  // 마스터 저장
        const {goods_no, ep_thumbnail_img, ep_thumbnail_org} = this.item;
        // 마스터 저장
        const res = await this.$api.postJson('/goods/ep/setEpThumbnail', {goods_no, ep_thumbnail_img, ep_thumbnail_org});
        if (res) {
          if (res.ok) {
            this.$alertTop(`상품 ${goods_no} EP 이미지가 수정되었습니다.`);
          }
        }
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    async handleTempFile(event) {
      if (event.dataTransfer && event.dataTransfer.effectAllowed === 'move') {
        return;
      }
      if (this.busy.uploadTemp) return this.$modal.show({title: '업로드 에러 확인', html: '이미지 업로드가 이미 진행중입니다. 잠시 후 다시 시도해 주세요.'});
      let files = [...(event.dataTransfer || event.target).files];
      if (!files.length) return this.$modal.show({title: '업로드 에러 확인', html: '파일을 업로드해 주세요.'});

      if (this.item.temp_images.length + files.length > 10) {
        return this.$modal.show({title: '업로드 에러 확인', html: '이미지는 최대 10장까지 업로드할 수 있습니다.'});
      }
      if (files.some(file => !'jpg,jpeg,png,bmp,webp'.split(',').includes(file.name.toLowerCase().split('.').pop()))) {
        return this.$modal.show({title: '업로드 에러 확인', html: 'jpg, jpeg, png, bmp, webp 파일을 업로드해주세요'});
      }

      this.busy.uploadTemp = true;
      const j = await this.$api.postForm('/master/uploadImages', {
        files: (event.dataTransfer || event.target).files
      });
      this.busy.uploadTemp = false;

      if (j) {
        this.item.temp_images = this.item.temp_images.concat(j.imgObjList);
      }
    },
    async handleFile(event) {
      if (event.dataTransfer && event.dataTransfer.effectAllowed === 'move') {
        return;
      }
      if (this.busy.upload) return this.$modal.show({title: '업로드 에러 확인', html: '이미지 업로드가 이미 진행중입니다. 잠시 후 다시 시도해 주세요.'});
      let files = [...(event.dataTransfer || event.target).files];
      if (!files.length) return this.$modal.show({title: '업로드 에러 확인', html: '파일을 업로드해 주세요.'});

      if (this.item.images.length + files.length > 10) {
        return this.$modal.show({title: '업로드 에러 확인', html: '이미지는 최대 10장까지 업로드할 수 있습니다.'});
      }
      if (files.some(file => !'jpg,jpeg,png,bmp,webp'.split(',').includes(file.name.toLowerCase().split('.').pop()))) {
        return this.$modal.show({title: '업로드 에러 확인', html: 'jpg, jpeg, png, bmp, webp 파일을 업로드해주세요'});
      }

      this.busy.upload = true;
      const j = await this.$api.postForm('/master/uploadImages', {
        files: (event.dataTransfer || event.target).files
      });
      this.busy.upload = false;

      if (j) {
        if (j.ok) {
          this.item.images = this.item.images.concat(j.imgObjList);
        } else {
          return this.$modal.show({title: '업로드 에러 확인', html: j.msg});
        }
      }
    },
    async handleEpFile(event) {
      if (this.busy.upload) return this.$modal.show({title: '업로드 에러 확인', html: '이미지 업로드가 이미 진행중입니다. 잠시 후 다시 시도해 주세요.'});

      let files = [...(event.dataTransfer || event.target).files];
      if (!files.length) return this.$modal.show({title: '업로드 에러 확인', html: '파일을 업로드해 주세요.'});
      if (files.some(file => !'jpg,jpeg,png,bmp,webp'.split(',').includes(file.name.toLowerCase().split('.').pop()))) {
        return this.$modal.show({title: '업로드 에러 확인', html: 'jpg, jpeg, png, bmp, webp 파일을 업로드해주세요'});
      }
      this.busy.upload = true;

      const j = await this.$api.postForm('/goods/ep/uploadImages', {
        files: (event.dataTransfer || event.target).files
      });
      this.busy.upload = false;

      if (j) {
        if (j.ok) {
          const [imgObj] = j.imgObjList;

          this.item.ep_thumbnail_org = imgObj.url;
          this.item.ep_thumbnail_img = `https://i.balaan.io/${imgObj.jpg}`;
        } else {
          return this.$modal.show({title: '업로드 에러 확인', html: j.msg});
        }
      }
    },
    copyText(e) {
      let res = this.$utils.copyToClipboard(e);
      if (res) this.$alertTop(`"${e}" 로 복사되었습니다`);
    },
  }
}
</script>
