<template>
  <div>
    <ep-image-replace v-bind="{shop, shopMap, brand, brandMap, category, categoryMap, defaultForm}"></ep-image-replace>
  </div>
</template>

<script>
import EpImageReplace from './EpImageReplace';

export default {
  name: 'EpImage',
  title: 'EP 이미지 관리',
  components: {EpImageReplace},
  data() {
    return {
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      defaultForm: {
        search: '',
        shop: [],
        brand: [],
        category: [],

        goods_status: 'registered',
        stock_status: 'normal',
        display_status: 'view',
        isMatchingGm: 'ALL',
        sortKey: 'gm_id',
        sortDir: 'desc',

        goodsType: 'ALL',
        usedGrade: this.$C.USED_GRADE.map(e => e.value),

        goods_no_include: '',
        goods_no_exclude: '',
        gm_id: '',
        gm_id_exclude: '',

        limit: 100,
        skip: 0,
      },
      form: {},
      fields: {
        list: [

        ]
      },
      itemList: [],
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
  async created() {
    this.form = this.$utils.clone(this.defaultForm);
    let meta = await this.$api.getMeta('shop,brand,category');
    meta.shop.forEach(s => {
      s.value = s.boutique;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    }); // use_yn 무관 일단 정보는 필요
    this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);

    this.brand = meta.brand.map(e=>{
      return this.brandMap[e.brand_no] = {...e, value:e.brand_no, label:`${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a,b)=>a.label.localeCompare(b.label));

    this.category = meta.category.map(e=>{
      return this.categoryMap[e.category] = {...e, value:e.category, label:`${e.category} (${e.category_nm})`};
    }).sort((a,b)=>(a.value.length - b.value.length)*10 + a.value.localeCompare(b.value));
  }
}
</script>
