<template>
  <div>
    <b-input-group class="mb-2">
      <b-input-group-prepend>
        <b-button variant="primary" @click="list" :disabled="busy.search">
          <i class="fa fa-search"></i> 검색
          <b-spinner class="mr-1" small v-if="busy.search"></b-spinner>
        </b-button>
      </b-input-group-prepend>
      <b-form-input id="search" type="text" placeholder="" v-model="searchStr" @keypress.enter="search()" autocomplete="off" v-focus></b-form-input>
    </b-input-group>

    <div>
      총 {{ this.items.filteredList.length}} 건
    </div>
    <div v-if="busy.list" class="text-center text-dark mt-5">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <htb v-show="!busy.list" ref="htb" v-model="items.filteredList" :config="htbConfig" height="calc(100vh - 650px)"></htb>
    <b-btn @click="downCsv">CSV 다운로드</b-btn>

    <hr/>

    <b-row>
      <b-col>
        <div>매칭 추가, 변경 (발란코드,NaverProductId)</div>
        <b-textarea v-model="addNos" rows="10"></b-textarea>
        <b-btn class="mt-1" variant="success" @click="add()" :disabled="busy.add">추가<b-spinner class="ml-1" small v-if="busy.add"></b-spinner></b-btn>
        <b-btn class="mt-1 ml-1" variant="primary" @click="replace()" :disabled="busy.replace">교체<b-spinner class="ml-1" small v-if="busy.replace"></b-spinner></b-btn>
      </b-col>
      <b-col>
        <div>매칭 제거 (발란코드)</div>
        <b-textarea v-model="removeNos" rows="10"></b-textarea>
        <b-btn class="mt-1" variant="danger" @click="remove()" :disabled="busy.remove">제거<b-spinner class="ml-1" small v-if="busy.remove"></b-spinner></b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
</style>

<script>
import {down} from '@/shared/impexp';

export default {
  name: 'NaverADApiLogs',
  title: '원부매칭 관리',
  data() {
    return {
      searchStr: '',
      lastBody: {list: {}},
      items: {list: [], filteredList: []},
      busy: {list: false, listmore: false, search: false, add: false, replace: false, remove: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      addNos: '',
      removeNos: '',

      htbConfig: {}
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      await this.$api.postTable(this, '/goods/ep/epIds', {}, {fnAssign: this.assignTableData});
      this.search();
    },
    assignTableData() {
    },
    async search() {
      this.busy.search = true;
      if (this.searchStr) {
        const re = new RegExp(this.searchStr.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&')); // escape regexp
        this.items.filteredList = this.items.list.filter(e => {
          return re.test(e.goods_no) || re.test(e.ep_id);
        });
      } else {
        this.items.filteredList = this.items.list;
      }
      this.render();
      this.busy.search = false;
    },
    downCsv() {
      down(this.items.filteredList, ['발란코드', 'NaverProductId'], ['goods_no', 'ep_id'], `GoodsNo_EpId`, 'csv');
    },
    async add() {
      const lines = this.addNos.trim().split(/\r?\n/).map(e => e.trim()).filter(e => e);
      if (lines.some(e => !e.match(/\d{5,9},\d{9,13}/))) {
        return alert('형식이 맞지 않는 줄이 있습니다. 발란코드,NaverProductId 순으로 띄어쓰기 없이 입력해주세요');
      }
      const rows = lines.map(e => {
        const [g, n] = e.split(',');
        return {goods_no: +g, ep_id: n};
      });
      this.busy.add = true;
      const j = await this.$api.postJson('/goods/ep/manageEpId', {type: 'add', rows});
      this.busy.add = false;
      if (j) {
        this.addNos = '';
        this.$utils.alert('추가되었습니다.');
        this.list();
      }
    },
    async replace() {
      const lines = this.addNos.trim().split(/\r?\n/).map(e => e.trim()).filter(e => e);
      if (lines.some(e => !e.match(/\d{5,9},\d{9,13}/))) {
        return alert('형식이 맞지 않는 줄이 있습니다. 발란코드,NaverProductId 순으로 띄어쓰기 없이 입력해주세요');
      }

      if (!confirm(`입력하신 ${lines.length} 건의 데이터 외에는 제거됩니다. 계속하시겠습니까?`)) return;

      const rows = lines.map(e => {
        const [g, n] = e.split(',');
        return {goods_no: +g, ep_id: n};
      });
      this.busy.replace = true;
      const j = await this.$api.postJson('/goods/ep/manageEpId', {type: 'replace', rows});
      this.busy.replace = false;
      if (j) {
        this.addNos = '';
        this.$utils.alert('교체되었습니다.');
        this.list();
      }
    },
    async remove() {
      const lines = this.removeNos.trim().split(/\r?\n/).map(e => e.trim()).filter(e => e);
      if (lines.some(e => !e.match(/\d{5,9}/))) {
        return alert('형식이 맞지 않는 줄이 있습니다. 발란코드만 입력해주세요');
      }
      const rows = lines.map(e => {
        return {goods_no: +e};
      })
      this.busy.remove = true;
      const j = await this.$api.postJson('/goods/ep/manageEpId', {type: 'remove', rows});
      this.busy.remove = false;
      if (j) {
        this.removeNos = '';
        this.$utils.alert('제거되었습니다.');
        this.list();
      }
    },
    render() {
      setTimeout(() => this.$refs.htb.hotInstance.render(), 0);
    },
  }
}
</script>
