<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="발란코드, 마스터 ID를 입력해주세요." v-model="form.search" @keypress.enter.prevent.stop="list" autocomplete="off"
                      v-focus></b-form-input>
      </b-input-group>
      <div class="flex-row flex-wrap d-flex justify-content-start mt-1">
        <template v-for="(f, idx) in formOptions.filter(e => !e.role || $R(e.role))">
          <div v-if="f.key === 'divider'" class="w-100" :key="idx"></div>
          <div v-else class="flex-grow-0 mb-1 mr-2" :key="f.key">
            <div><small class="mb-n2">{{ f.name }}</small></div>
            <b-btn-group class="d-block">
              <b-btn v-for="o in f.options" size="sm" :key="o.value"
                     :variant="form[f.key] === o.value ? (o.variant || (o.value === 'ALL' ? 'dark' : '')): 'light'"
                     @click="o.click ? o.click() : form[f.key] = o.value">
                {{ o.text }}
              </b-btn>
            </b-btn-group>
          </div>
        </template>
        <div class="flex-grow-0 mb-1 mr-2">
          <small>한 번에 가져올 상품 수</small><br/>
          <b-form-input class="text-center w-100px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.limit" @keypress.enter="list"></b-form-input>
        </div>
      </div>

      <b-collapse id="collapse1" v-model="collapse.detail">
        <hr/>
        <b-row>
          <b-col class="mb-1" cols="12" md="12" xl="4">
            <template v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')">
              <shop-preset v-model="form.shop"></shop-preset>
            </template>
          </b-col>
          <b-col class="mb-1" cols="12" md="6" xl="4">
            <brand-preset class="" v-model="form.brand" :hideDisabled="true"></brand-preset>
          </b-col>
          <b-col class="mb-1" cols="12" md="6" xl="4">
            <category-preset class="" v-model="form.category"></category-preset>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col cols="12" lg="6">
            <div><small class="mb-n2">상품유형</small></div>
            <b-form inline>
              <b-form-radio-group class="col-form-label" v-model="form.goodsType" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '새상품만', value: 'new'},
                  {text: '빈티지만', value: 'used'}
                ]"></b-form-radio-group>
              <template v-if="form.goodsType === 'used'">
                <b-button class="mr-1" size="sm" variant="light" @click="toggleUsedGrade()">전체</b-button>
                <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
                <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
                <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
                <b-form-checkbox-group v-model="form.usedGrade">
                  <b-form-checkbox v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{s.text}}</b-form-checkbox>
                </b-form-checkbox-group>
              </template>
            </b-form>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <small>발란코드</small>
            <b-row>
              <b-col class="w-50">
                <b-form-textarea :rows="2" v-model="form.goods_no_include" placeholder="발란코드를 입력해주세요"></b-form-textarea>
              </b-col>
              <b-col class="w-50">
                <b-form-textarea :rows="2" v-model="form.goods_no_exclude" placeholder="제외할 발란코드를 입력해주세요"></b-form-textarea>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <small>마스터 ID</small>
            <b-row>
              <b-col class="w-50">
                <b-form-textarea :rows="2" v-model="form.gm_id" placeholder="마스터 ID를 입력해주세요"></b-form-textarea>
              </b-col>
              <b-col class="w-50">
                <b-form-textarea :rows="2" v-model="form.gm_id_exclude" placeholder="제외할 마스터 ID를 입력해주세요"></b-form-textarea>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-collapse>
      <div class="mt-2 clearfix">
        <b-button class="" variant="primary" @click="list" :disabled="busy.list">
          검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
        <b-button class="ml-1" variant="warning" @click="resetForm">초기화</b-button>
        <b-button class="ml-1" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>
      </div>
    </b-card>

    <div class="clearfix">
      <b-button :disabled="itemMode !== 'ep'" class="mr-2" :variant="itemMode !== 'ep' ? 'secondary' : 'outline-primary'" @click="replaceEpThumbnail('confirmed')">발란코드 이미지로 원복</b-button>
      <b-button :disabled="itemMode !== 'catalog'"  :variant="itemMode !== 'catalog' ? 'secondary' : 'outline-success'" @click="replaceEpThumbnail('catalog')">EP 이미지를 카탈로그 이미지로 변경</b-button>
      <small class="ml-1 text-muted">*EP 이미지 변경 시 네이버 쇼핑 반영은 최대 2시간 소요됩니다.</small>

      <div class="pull-right">
        <b-dropdown right variant="light" class="m-1">
          <template #button-content>
            <i class="fa fa-copy"></i>
          </template>
          <b-dropdown-item @click="copy('goods_no')">발란코드</b-dropdown-item>
          <b-dropdown-item @click="copy('gm_id')">마스터 ID(매칭된 것만)</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-card>
      <div ref="toolbar" class="bg-white rounded p-2">
        <div class="clearfix">

          <b-row>
            <b-col cols="3">
              <b-button-group>
                <b-button :variant="picGroup === 'ALL' ? 'primary' : 'light'" @click="picGroup = 'ALL'">
                  {{items.list.length}} 개 전체 아이템
                </b-button>
                <b-button :variant="picGroup === 'selected' ? 'success' : 'light'" @click="picGroup = 'selected'">
                  {{items.list.filter(e=>e.selected).length}} 개 선택된 아이템
                </b-button>
              </b-button-group>
            </b-col>
            <b-col>
              <b-form inline>
                <b-input-group class="ml-1">
                  <b-input-group-prepend><b-input-group-text>이미지 너비</b-input-group-text></b-input-group-prepend>
                  <b-form-input class="w-65px text-center" type="number" v-model.number="picWidth"></b-form-input>
                  <b-input-group-append><b-input-group-text>px</b-input-group-text></b-input-group-append>
                  <b-input-group-append><b-button @click="picWidth=175">175</b-button></b-input-group-append>
                  <b-input-group-append><b-button @click="picWidth=195">195</b-button></b-input-group-append>
                  <b-input-group-append><b-button @click="picWidth=225">225</b-button></b-input-group-append>
                  <b-input-group-append><b-button @click="picWidth=265">265</b-button></b-input-group-append>
                  <b-input-group-append><b-button @click="picWidth=318">318</b-button></b-input-group-append>
                  <b-input-group-append><b-button @click="picWidth=400">400</b-button></b-input-group-append>
                </b-input-group>
                <b-checkbox inline class="ml-2" v-model="picInfoTop">상단정보</b-checkbox>
                <b-checkbox inline class="ml-2" v-model="picInfoBottom">하단정보</b-checkbox>
                <b-button-group class="ml-1">
                  <b-button :variant="itemMode === 'ep' ? 'dark' : 'light'" @click="itemMode = 'ep'">EP 이미지</b-button>
                  <b-button :variant="itemMode === 'catalog' ? 'dark' : 'light'" @click="itemMode = 'catalog'">카탈로그 이미지</b-button>
                </b-button-group>
              </b-form>
            </b-col>

            <b-col cols="2">
              <b-button-group class="m-1">
                <b-button variant="primary" @click="_=>{items.list.filter(e=>(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)).forEach(e=>e.selected = true);recalcPicFilteredCnt();$forceUpdate()}">전체선택</b-button>
                <b-button variant="warning" @click="_=>{items.list.filter(e=>(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)).forEach(e=>e.selected = false);recalcPicFilteredCnt();$forceUpdate()}">선택해제</b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </div>
        <drag-select ref="drag" class="flex-row flex-wrap d-flex" attribute="attr" selectorClass="itemToBeSelected" @change="dragSelectItems">
          <div v-for="e of items.list"
               v-if="(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)"
               :key="e.id" :attr="e.goods_no" :selected="e.selected ? 1 : 0"
               class="flex-grow-0 m-1 position-relative itemToBeSelected"
               :style="{width:(picWidth * 2 + picTmpSize)+'px', padding:'3px', border:e.selected ? '3px solid #20a8d8' : '1px solid #c8ced3'}"
               @click="clickItem($event, e)">

            <b-row>
              <b-col cols="6" class="pr-1">
                <div>
                  <div class="p-1">
                    <div class="clearfix pb-1">
                      <div v-if="picInfoTop">
                        <a :href="'/#/master/matching/' + e.goods_no" target="_blank" class="badge badge-primary">{{e.goods_no}}<i class="fa fa-external-link"></i></a>
                        <div class="pull-right">
                          <b-badge class="pointer" style="right: 0px" size="sm" @click.prevent.stop="showModal(e, 'detailImg')">상세</b-badge><br/>
                        </div>
                      </div>
                    </div>
                    <img :src="e.img_urls && e.img_urls.length ? e.img_urls[0] : defaultBalaanImg" :data-id="e.id" class="w-100 border"/>
                      <div v-if="picInfoBottom" class="info-bottom" style="line-height:18px">
                        <b-badge variant="primary">{{e.matched_sku_id}}</b-badge><br/>
                        <b-badge class="text-truncate overflow-hidden mt-1 text-left" :title="e.cate" style="max-width:100%;width:fit-content;">{{e.cate}}</b-badge><br/>
                       </div>
                  </div>
                </div>
              </b-col>
              <b-col v-if="itemMode === 'ep'" cols="6" class="pl-1">
                <div>
                  <div class="p-1">
                    <div  v-if="picInfoTop" class="pb-1">
                      EP 이미지
                      <div class="pull-right">
                        <b-badge class="pointer" style="right: 0px" size="sm" @click.prevent.stop="showModal(e, 'imgUploadCf')">이미지 업로드</b-badge><br/>
                      </div>
                    </div>
                    <img :src="e.ep_thumbnail_img ? e.ep_thumbnail_img : defaultBalaanImg" :data-id="e.id" class="w-100 border"/>
                  </div>
                </div>
              </b-col>
              <b-col v-else cols="6" class="pl-1">
                <div>
                  <div class="p-1">
                    <div class="clearfix pb-1">
                      <div v-if="picInfoTop">
                        <div v-if="gmMap[e.gm_id]">
                          <a :href="'/#/master/list/' + e.gm_id" target="_blank" class="badge badge-success">{{e.gm_id}}<i class="fa fa-external-link"></i></a>
                          <div class="pull-right">
                            <b-badge class="pointer" style="right: 0px" size="sm" @click.prevent.stop="showModal(gmMap[e.gm_id], 'imgUpload')">이미지 업로드</b-badge><br/>
                          </div>
                        </div>
                        <div v-else><small>매칭 카탈로그 없음</small></div>

                        <div v-if="gmMap[e.gm_id]" class="position-absolute text-right mt-2" style="right:25px;line-height:15px">
                          <b-badge v-if="gmMap[e.gm_id].processing_status === 'processing'" variant="primary">검수 전</b-badge>
                          <b-badge v-else-if="gmMap[e.gm_id].processing_status === 'registered'" variant="dark">검수 후</b-badge>
                          <b-badge v-else variant="danger">삭제</b-badge>
                          <b-badge v-if="gmMap[e.gm_id].display_status === 'view'" variant="dark" class="ml-1">노출</b-badge>
                          <b-badge v-else variant="danger" class="ml-1">미노출</b-badge>
                        </div>
                      </div>
                    </div>
                    <img :src="gmMap[e.gm_id] ? gmMap[e.gm_id].ep_img_url : defaultBalaanImg" :data-id="e.id" class="w-100 border"/>
                      <div v-if="picInfoBottom" class="info-bottom" style="line-height:18px">
                        <b-badge variant="primary">{{(gmMap[e.gm_id] || {}).designer_sku}}</b-badge><br/>
                        <b-badge class="text-truncate overflow-hidden mt-1 text-left" :title="e.cate" style="max-width:100%;width:fit-content;">{{(gmMap[e.gm_id] || {}).cate}}</b-badge><br/>
                      </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </drag-select>
        <div></div>
        <div v-if="hasMore.list" class="text-center py-3">
          <b-button variant="primary" size="lg" @click="list(true)">더 가져오기</b-button>
        </div>
      </div>
    </b-card>
    <div @click="scrollTo('toolbar')" class="text-right pointer" style="bottom: 1rem; position: fixed; right: 2rem; width: fit-content; z-index: 10;">
      <b-button pill variant="outline-secondary">Top<i class="ml-1 fa fa-arrow-up"></i></b-button>
    </div>
<!--    <master-confirmed-modal ref="masterConfirmedModal" v-bind="{modal, shopMap, targetMap, gmStatusKrMap}"></master-confirmed-modal>-->
    <ep-image-modal ref="epImageModal" v-bind="{modal}"></ep-image-modal>
  </div>
</template>

<script>
import DragSelect from '@/views/modules/DragSelect.vue'
import epImageModal from "@/views/goods/EP/EpImageModal";

export default {
  name: 'EpImageReplace',
  title: 'EP 이미지 교체',
  components: {DragSelect, epImageModal},
  props: ['shop', 'shopMap', 'brand', 'brandMap', 'category', 'categoryMap', 'defaultForm'],
  data() {
    return {
      form: {},
      lastBody: {list: {}},
      items: {list: []},
      gmMap: {},
      busy: {list: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      collapse: {detail: true},
      picTmpSize: 30,
      itemMode: 'catalog',
      picGroup: 'ALL',
      picFilter: '',
      picFilteredCnt: 0,
      picWidth: 175,
      picInfoTop: true,
      picInfoBottom: true,
      modal: {detail:false, detailImg: false, imgUpload: false, imgUploadCf: false},
      formOptions: [
        {
          name: '프리셋', key: 'preset', options: [
            {text: '전체', click: () => {
                this.form.goods_status = 'ALL';
                this.form.stock_status = 'ALL';
                this.form.display_status = 'ALL';
              }, variant: 'outline-dark'},
            {text: '등록상품', click: () => {
                this.form.goods_status = 'registered';
                this.form.stock_status = 'ALL';
                this.form.display_status = 'ALL';
              }, variant: 'outline-info'},
            {text: 'Live', click: () => {
                this.form.goods_status = 'registered';
                this.form.stock_status = 'normal';
                this.form.display_status = 'view';
              }, variant: 'outline-primary'},
            {text: '미노출', click: () => {
                this.form.goods_status = 'ALL';
                this.form.stock_status = 'ALL';
                this.form.display_status = 'notview';
              }, variant: 'outline-warning'},
          ]
        },
        {
          name: '등록상태', key: 'goods_status', options: [
            {text: '전체', value: 'ALL'},
            {text: 'Processing', value: 'processing', variant: 'info'},
            {text: 'Registered', value: 'registered', variant: 'success'},
            {text: 'Terminated', value: 'terminated', variant: 'danger'}
          ]
        },
        {
          name: '재고상태', key: 'stock_status', options: [
            {text: '전체', value: 'ALL'},
            {text: '재고있음', value: 'normal', variant: 'success'},
            {text: '품절', value: 'runout', variant: 'warning'}
          ]
        },
        {
          name: '노출상태', key: 'display_status', options: [
            {text: '전체', value: 'ALL'},
            {text: '노출', value: 'view', variant: 'success'},
            {text: '미노출', value: 'notview', variant: 'warning'}
          ]
        },
        {
          name: '마스터 매칭', key: 'isMatchingGm', options: [
            {text: '전체', value: 'ALL'},
            {text: '매칭', value: true, variant: 'success'},
            {text: '미매칭', click: () => {
                this.form.isMatchingGm = false;
                this.form.sortKey = 'goods_no';
              }, value: false, variant: 'warning'}
          ]
        },
        {
          name: '정렬기준', key: 'sortKey', options: [
            {text: '발란코드', value: 'goods_no', variant: 'primary'},
            {text: '마스터 ID', value: 'gm_id', variant: 'primary'},
          ]
        },
        {
          name: '정렬방향', key: 'sortDir', options: [
            {text: '높은순', value: 'desc', variant: 'primary'},
            {text: '낮은순', value: 'asc', variant: 'primary'},
          ]
        },
      ],
      gmStatusKrMap: {
        'temp_matched': '임시 매칭',
        'confirmed': '확정 매칭',
        'excl_five_minutes': '[보류]5분 초과',
        'excl_multioption': '[보류]모듬상품',
        'excl_single_partner': '[보류]단일 파트너',
        'excl_wrong_brand': '[보류]브랜드 오류',
        'excl_etc': '[보류]기타',
      },
      targetMap: { // 상품링크 매칭에 사용됨
        godo: '고도몰',
        lotteimall: '롯데아이몰',
        smartstore: '스토어팜(발란)',
      },
      defaultBalaanImg: 'https://djp5oonlusoz4.cloudfront.net/goods/personal_order.jpg',
    }
  },
  async beforeMount() {
    this.resetForm();
  },
  watch: {
    picFilter(v) {
      if (v) {
        this.items.list.forEach(e=>{
          let uv = v.toUpperCase();
          e.filtered = e.name.toUpperCase().includes(uv)
              || e.id.includes(uv)
              || e.designer_sku.toUpperCase().includes(uv)
              || (e.brand_nm || '').toUpperCase().includes(uv)
              || (e.category_nm || '').toUpperCase().includes(uv)
        });
        this.picFilteredCnt = this.items.list.filter(e=>e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
      }
    },
    picGroup(v) {
      this.picFilteredCnt = this.items.list.filter(e=>e.filtered && (v === 'ALL' || e.selected)).length;
    },
    itemMode() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    picWidth() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    perPage() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
  },
  methods: {
    async list(more) {
      this.busy.list = true;
      const form = this.form;
      if (form.limit > 1000) {
        this.busy.list = false;
        return alert('1000개를 초과하여 검색할 수 없습니다.');
      }

      const shop = form.shop.length === this.shop.length ? [] : form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);

      let goods_no_include = this.form.goods_no_include ? this.form.goods_no_include.trim().split(/\D+/g).map(e => +e) : [];
      let goods_no_exclude = this.form.goods_no_exclude ? this.form.goods_no_exclude.trim().split(/\D+/g).map(e => +e) : [];
      if (goods_no_include.length) {
        goods_no_include = goods_no_include.filter(e => !~goods_no_exclude.indexOf(e));
        goods_no_exclude = [];
      }

      const gm_id = this.form.gm_id ? this.form.gm_id.trim().split(/\r?\n/g).map(e => e.trim()).filter(e => e) : [];
      const gm_id_exclude = this.form.gm_id_exclude ? this.form.gm_id_exclude.trim().split(/\r?\n/g).map(e => e.trim()).filter(e => e) : [];

      const body = {
        ...form,
        shop,
        brand,
        category,
        goods_no_include,
        goods_no_exclude,
        gm_id,
        gm_id_exclude
      };

      const j = await this.$api.postTable(this, '/master/confirmed', body, {more, fnAssign: this.assignTableCfData});
      if (j) {
        const id_include = [...new Set(j.list.map(item => item.gm_id))].filter(e => e);
        if (id_include.length) {
          const gmRes = await this.$api.postJson('/master/list', {id_include, fields: [], limit: form.limit});
          if (gmRes) {
            gmRes.list.forEach(this.assignTableGmData)
          }
        }
        const needGmList = this.items.list.map(e => e.gm_id);
        Object.keys(this.gmMap).filter(id => !needGmList.includes(id)).forEach(id => delete this.gmMap[id]);
      }
      this.busy.list = false;
      this.$forceUpdate();
    },
    isClothes (category_code) {
      return category_code.slice(1, 3) === 'CL';
    },
    assignTableCfData(e) {
      if (e.category) {
        let cates = [];
        for (let i = 3; i <= e.category.length; i += 3) {
          if (e.category.substring(i - 3, i) === '001') cates.push('전체');
          else cates.push(this.categoryMap[e.category.substring(0, i)] && this.categoryMap[e.category.substring(0, i)].category_nm || '?');
        }
        e.cate = cates.join(' > ');
      }

      if (!e.consumer) e.consumer = e.price;
      if (!e.ep_thumbnail_img) e.ep_thumbnail_img = e.img_urls[0];
      // 배송유형 및 일자 확정
      let shop = this.shopMap[e.shop_id];
      if (shop) {
        if (shop.delivery_type !== 'both') {
          e.delivery_type = {abroad:'해외', domestic:'국내'}[shop.delivery_type];
          e.delivery_day = shop.delivery_day;
        } else {
          if (e.delivery_type === '국내') {
            e.delivery_day = shop.delivery_day;
          } else {
            e.delivery_day = shop.delivery_day_abroad || 21;
          }
        }
        e.delivery_str = e.delivery_type + (shop.logistics === 'direct' ? (e.delivery_type === '해외' && shop.pccc_feed === 'y' ? '구매대행' : '직배송') : '');
      } else {
        e.delivery_day = 21; // 이전부터 있던 하드코딩, 6일인 이유는?
        e.delivery_str = 'SHOP배송정보없음';
      }
      e.godoPrice = this.godoPriceLambda(e.price, {category: e.category});
      e.ssPrice = e.price;

      e.img = e.images && e.images[0] ? `https://i.balaan.io/${e.image_path}${e.images[0].thumb_200}` : e.img_urls[0];
    },
    assignTableGmData(e) {
      if (e.category) {
        let cates = [];
        for (let i = 3; i <= e.category.length; i += 3) {
          if (e.category.substring(i - 3, i) === '001') cates.push('전체');
          else cates.push(this.categoryMap[e.category.substring(0, i)] && this.categoryMap[e.category.substring(0, i)].category_nm || '?');
        }
        e.cate = cates.join(' > ');
      }

      if (!e.ep_img_url) {
        const isClothes = /^...002/.test(e.category);
        if (e.img_urls && e.img_urls.length) {
          e.ep_img_url = isClothes ? (e.img_urls[1] || e.img_urls[0]) : e.img_urls[0];
        } else if (e.temp_img_urls && e.temp_img_urls.length) {
          e.ep_img_url = isClothes ? (e.temp_img_urls[1] || e.temp_img_urls[0]) : e.temp_img_urls[0];
        }
      }

      this.gmMap[e.id] = e;
    },
    godoPriceLambda(price) {
      return price;
    },
    async replaceEpThumbnail (type) {
      const targetItems = this.items.list.filter(e => e.selected);
      const alertStr = type === 'confirmed' ? '발란코드 이미지로 원복할 상품을 선택해주세요.' : '카탈로그 이미지로 교체할 상품을 선택해주세요.';
      const confirmStr = `${targetItems.length}개 상품에 대해 이미지 작업이 시작됩니다`;

      if (!targetItems.length) {
        return alert(alertStr);
      }

      if (!confirm(confirmStr)) {
        return;
      }
      const body = {goodsNos: targetItems.map(e => e.goods_no), type};
      const j = await this.$api.postJson('/goods/ep/replaceEpThumbnail', body);
      if (j) {
        alert(`${j.cnt}개 상품에 대해 이미지 작업이 완료되었습니다.`);
      }
    },
    clickItem(e, item) {
      item.selected = !item.selected;

      this.$forceUpdate();
    },
    dragSelectItems(divs) {
      const selectedMap = {};
      this.items.list.forEach(e => selectedMap[e.goods_no] = e.selected);
      divs.map(e => e.getAttribute('attr')).forEach(e => selectedMap[e] = true);
      this.items.list.forEach(e => e.selected = !!selectedMap[e.goods_no]);

      this.$forceUpdate();
    },
    recalcPicFilteredCnt() {
      this.picFilteredCnt = this.items.list.filter(e=>e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
    },
    resetForm() {
      this.form = this.$utils.clone(this.defaultForm);
    },
    scrollTo(ref) {
      const y = this.$refs[ref].offsetTop - this.$refs[ref].clientHeight - 20;
      window.scroll({
        top: y,
        left: 0,
        behavior: 'smooth',
      })
    },
    copy(col, {withQuotes = false} = {}) {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('복사할 상품을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.filter(e => e[col]).map(e => withQuotes ? `'${e[col].toString().replace(/'/g, "\\'")}'` : e[col]).join(withQuotes ? ',\n' : '\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },
    copyOne(content) {
      if (this.$utils.copyToClipboard(content)) this.$alertTop(`복사되었습니다`);
    },
    showModal(row, type) {
      this.$refs.epImageModal.showModal(row, type);
    },
    toggleUsedGrade(grade) {
      if (!grade) {
        this.form.usedGrade = this.form.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.form.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },
  }
}
</script>
