<template>
  <div>
    <b-alert variant="danger" show>개발중인 화면입니다. 실제로 EP 에 반영되지는 않으니 자유롭게 테스트 가능합니다.</b-alert>
    <b-tabs v-model="tabIndex" card pills>

      <b-tab title="주요 필드 확인">

        <h5>생성된 데이터 확인</h5>
        <htb ref="htbEP" v-model="epRows" style="height: 300px"
             :config="epConfig" v-bind="{controls: true, enableAddRows: false, enableRemoveRows: false, filePrefix: 'EP_생성데이터'}">
        </htb>
        <b-row>
          <b-col md="6" class="p-0">
            <small>지정된 발란코드에 대해 확인</small><br/>
            <b-textarea v-model="sampleGoodsNos" rows="4" placeholder="발란코드를 엔터로 구분해서 넣어주세요"></b-textarea>
            <b-btn variant="success" class="mt-1" @click="getEpSamples()">검색</b-btn>
          </b-col>
        </b-row>

        <hr/>

        <h5>26. 제품코드(manufacture_define_number)</h5>
        <b-alert variant="success" class="mb-2" show>마스터의 designer_sku, cf.matched_sku_id, gd_goods.shortdesc 순으로 체크 후 값이 결정됩니다</b-alert>
        <div class="bg-gray-100 p-2">
          1A00010 5955N 99901
        </div>

        <hr/>

        <h5>27. 브랜드(brand), 29. 제조사(maker)</h5>
        <b-alert variant="success" class="mb-2" show>브랜드의 한글명을 띄어쓰기를 제거 후 입력합니다.</b-alert>
        <div class="bg-gray-100 p-2">
          마크앤로나
        </div>

        <hr/>

        <h5>32. 이벤트(event_words)</h5>
        <b-alert variant="success" class="mb-2" show>EP 생성시 아래의 값이 고정으로 들어갑니다.</b-alert>
        <div class="bg-gray-100 p-2">
          #20만원 즉시 할인 #전상품 무료배송 #최대 5% 적립 #100만 신상품 #최대 80% 할인 #카드사별 2~6개월 무이자 혜택
        </div>

        <hr/>

        <h5>33. 일반/제휴쿠폰(coupon)</h5>
        <b-alert variant="success" class="mb-2" show>EP 생성시 아래의 값이 고정으로 들어갑니다.</b-alert>
        <div class="bg-gray-100 p-2">
          20000원^^
        </div>

        <hr/>

        <h5>35. 카드 무이자할부 정보(interest_free_event)</h5>
        <b-alert variant="success" class="mb-2" show>발란몰의 bl_card_interest 의 데이터 중 현재 시각에 적용되는 데이터 입니다.</b-alert>
        <div class="bg-gray-100 p-2">
          {{settings.cardInterestString}}
        </div>

        <hr/>

        <h5>36. 포인트(point)</h5>
        <b-alert variant="success" class="mb-2" show>EP 생성시 상품 가격에 따라 최대혜택가의 1% 금액이 들어갑니다.</b-alert>
        <div class="bg-gray-100 p-2">
          쇼핑몰자체포인트^3600
        </div>

        <hr/>

        <h5>52. 성별(gender)</h5>
        <b-alert variant="success" class="mb-2" show>009, 011003 은 여성, 010, 011002 는 남성, 그 외에는 빈 값으로 들어갑니다. blu.categoryToGender 에서 결정됩니다.</b-alert>
        <div class="bg-gray-100 p-2">
          여성
        </div>
      </b-tab>
      <b-tab title="카드할인 관리">
        <div class="mb-2 fs-15 bold">
          <i class="fa fa-book"></i> EP 가이드 - 할인카드명/카드할인가 항목을 참고해주세요
          <b-btn size="sm" variant="light" v-b-toggle.collapseCard>가이드 확인</b-btn>
        </div>
        <b-collapse id="collapseCard" class="mb-2">
          <img :src="`/img/naverEP/ep_field_card.png`"/>
        </b-collapse>

        <htb ref="htbCard" v-model="cardRows" :config="cardConfig" v-bind="{controls: true, addRowsPreset: [5, 10], filePrefix: 'EP_카드할인_설정'}"
             style="height: 300px; width: 100%; max-width: 700px" @afterChange="cardChange()">
        </htb>

        <hr/>

        <div>
          <b-form inline>
            <b-input v-model="cardSamplePrice" class="w-100px text-center"></b-input>
            <span class="col-form-label mx-1">만원</span>
            <date-input v-model="cardSampleDate"></date-input>
            <span class="col-form-label ml-1">일자 기준 적용된 샘플을 확인해주세요(유효한 행만 계산됩니다).</span>
          </b-form>
        </div>
        <b-alert :variant="cardSampleVariant" class="mt-1 mb-2" show>{{cardFieldSample}}</b-alert>

        <div class="">
          <b-btn variant="info" class="mr-1" @click="loadCardSetting()" :disabled="busy.card">새로고침<b-spinner class="ml-1" small v-if="busy.card"></b-spinner></b-btn>
          <b-btn variant="outline-success mr-1" @click="checkRows()">Validation</b-btn>
          <b-btn variant="success" @click="saveCardSetting()" :disabled="busy.card">저장하기<b-spinner class="ml-1" small v-if="busy.card"></b-spinner></b-btn>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import htb from '@/views/modules/HotTableBase.vue'

export default {
  name: 'EpProps',
  title: 'EP 추가속성 관리',
  components: {htb},
  data() {
    return {
      tabIndex: 0,
      busy: {epSamples: false, settings: false, card: false},
      settings: {},

      epRows: [],
      epConfig: {
        keys: [
          'id',
          'title',
          'price_pc',
          'price_mobile',
          'normal_price',
          'link',
          'mobile_link',
          'image_link',
          'add_image_link',
          'category_name1',
          'category_name2',
          'category_name3',
          'category_name4',
          'naver_category',
          'naver_product_id',
          'import_flag',
          'manufacture_define_number',
          'brand',
          'origin',
          'option_detail',
          'seller_id',
          'gender',
          'shipping_settings',
          'today_pick',
          'today_pick_time',
          'live',
        ],
      },
      sampleGoodsNos: '',

      cardRows: [
        // {card: '삼성카드', rate: 2, st: '2023-05-23', ed: '2023-05-26'}
      ],
      cardConfig: {
        fields: [
          {name: '카드명', key: 'card'},
          {name: '할인율', key: 'rate', type: 'numeric', renderer: 'percent:1:1'},
          {name: '구간별 금액조건(만원)', key: 'priceCond', type: 'string'},
          {name: '구간별 할인금액(만원)', key: 'discountCond', type: 'string'},
          {name: '시작일', key: 'st', type: 'string'},
          {name: '종료일', key: 'ed', type: 'string'},
        ],
        settings: {
          readOnly: false,
          contextMenu: ['row_above', 'row_below', 'remove_row', 'copy'],
        }
      },
      cardSamplePrice: 2000000,
      cardSampleDate: this.$moment().format('YYYY-MM-DD'),
      cardFieldSample: '',
      cardSampleVariant: 'primary',
      cardSet: ['현대카드', '삼성카드', '롯데카드', 'KB국민카드', '하나카드', '우리카드', '씨티카드', '신한카드', 'SC제일카드', '대구카드', '부산카드', '광주카드', '경남카드',
        '전북카드', '제주카드', '카드명', 'NH농협카드', 'IBK기업은행카드', '수협카드', 'KDB산업카드', '신협카드', '우체국카드', '새마을금고카드', '산림조합카드', '저축은행카드',
        'BC카드', 'VISA카드', 'MASTERCARD카드', 'AMEX카드', 'JCB카드', 'Unionpay카드'],
    }
  },
  created() {
    this.getEpSamples().then();
    this.loadSettings().then();
    this.loadCardSetting().then();
  },
  watch: {
    tabIndex(v) {
      if (v === 1) setTimeout(() => this.$refs.htbCard.render(), 0);
    },
    cardSampleDate() {
      this.makeCardFieldSample();
    },
    cardSamplePrice() {
      this.makeCardFieldSample();
    }
  },
  methods: {
    async getEpSamples() {
      const goodsNos = this.sampleGoodsNos ? this.sampleGoodsNos.split(/\D+/g).filter(e => e).map(e => +e) : false;
      this.busy.epSamples = true;
      const j = await this.$api.postJson('/goods/ep/samples', {goodsNos});
      this.busy.epSamples = false;
      if (j) {
        this.epRows = j.list;
      }
    },
    /**
     * 카드사구분이 EP 에 있는 값인지, % 는 0 ~ 10 사이의 값인지, 일자가 비정상적인지, 일자가 겹치는 카드사 세팅이 있는지 체크한다.
     * @returns {boolean}
     */
    validateCardRows() {
      const targetRows = this.cardRows.filter(e => e.card);
      if (targetRows.some(e => !e.st && e.ed || !this.$moment(e.st).isValid() || !this.$moment(e.ed).isValid())) {
        this.$utils.alert('시작일과 종료일은 YYYY-MM-DD 형식으로 입력해주세요. ex) 2023-05-01');
        return false;
      }
      if (targetRows.some(e => e.rate && isNaN(e.rate))) {
        this.$utils.alert('할인율이 숫자가 아닌 행이 존재합니다');
        return false;
      }
      if (targetRows.some(e => [e.rate, e.priceCond && e.discountCond].filter(e => e).length !== 1)) {
        this.$utils.alert('(할인율) 혹은 (구간별 금액조건 과 구간별 할인금액) 중 하나를 입력해주세요');
        return false;
      }
      if (targetRows.some(e => e.priceCond && !/^\d+(\.\d+)?(\/\d+(\.\d+)?)*$/.test(e.priceCond))) {
        this.$utils.alert('구간별 금액조건은 띄어쓰기 없이 만원단위로 입력해주세요. ex) 3/5/10/20');
        return false;
      }
      if (targetRows.some(e => e.discountCond && !/^\d+(\.\d+)?(\/\d+(\.\d+)?)*$/.test(e.discountCond))) {
        this.$utils.alert('구간별 할인금액은 띄어쓰기 없이 만원단위로 입력해주세요. ex) 0.5/1/2/3/5');
        return false;
      }
      if (targetRows.some(e => e.priceCond && e.discountCond && e.priceCond.split('/').length !== e.discountCond.split('/').length)) {
        this.$utils.alert('구간별 금액조건과 구간별 할인금액의 구간 갯수가 다릅니다');
        return false;
      }
      const cardMul = this.$utils.arr2multi(targetRows, 'card');
      for (const [card, arr] of Object.entries(cardMul)) {
        if (!this.cardSet.includes(card)) {
          this.$utils.alert(`[${card}] - EP 가이드에 없는 카드명입니다`);
          return false;
        }
        if (arr.length > 1 && arr.some((e, i) => arr.some((f, j) => i !== j && (e.st <= f.ed && e.ed >= f.st)))) { // 기간이 겹친다면
          this.$utils.alert(`[${card}] - 기간이 겹치는 데이터가 존재합니다`);
        }
      }
      return true;
    },
    checkRows() {
      if (this.validateCardRows()) {
        this.$alertTop('데이터 체크가 완료되었습니다.');
      }
    },
    cardChange() {
      this.makeCardFieldSample();
    },
    makeCardFieldSample() {
      const today = this.cardSampleDate;
      const cardApplyRows = this.cardRows.filter(e => e.card && (e.rate || e.priceCond && e.discountCond) && e.st <= today && e.ed >= today);
      const results = [];
      for (const e of cardApplyRows) {
        if (e.rate) {
          results.push(`${e.card}^${this.$utils.round(this.cardSamplePrice * (100 - e.rate) / 100, 0)}`)
        } else if (e.priceCond && e.discountCond) {
          const price = e.priceCond.split('/');
          const dc = e.discountCond.split('/');
          const range = price.map((e, i) => [e, dc[i]]).keySort('0:-1');
          let priceIsInRange = false;
          for (const r of range) {
            if (this.cardSamplePrice >= r[0] * 10000) {
              results.push(`${e.card}^${this.cardSamplePrice - r[1] * 10000}`);
              priceIsInRange = true;
              break;
            }
          }
          if (!priceIsInRange) {
            results.push(`${e.card}^${this.cardSamplePrice}`);
          }
        }
      }
      this.cardFieldSample = results.join('|');
      if (this.cardFieldSample.length > 100) {
        this.cardSampleVariant = 'danger';
        this.cardFieldSample += ' (길이가 100 자를 초과합니다)'
      }
    },
    async loadSettings() {
      this.busy.settings = true;
      const j = await this.$api.getJson('/goods/ep/settings');
      this.busy.settings = false;
      if (j) {
        this.settings = j;
      }
    },
    async loadCardSetting() {
      this.busy.card = true;
      const j = await this.$api.postJson('/goods/ep/cardSetting');
      this.busy.card = false;
      if (j) {
        this.cardRows = j.list;
      }
    },
    async saveCardSetting() {
      if (!this.validateCardRows()) return;

      const cardRows = this.cardRows.filter(e => e.card && e.st && e.ed);
      this.busy.card = true;
      const j = await this.$api.postJson('/goods/ep/cardSetting', {cardRows});
      this.busy.card = false;

      if (j) {
        this.$alertTop('저장되었습니다');
      }
    }
  }
}
</script>
