<template>
  <div>
    <a class="btn btn-success pull-right" href="https://join.shopping.naver.com/misc/download/ep_guide.nhn" target="_blank">네이버 쇼핑 EP 제작 가이드</a>
    <b-tabs v-model="tabIndex">
      <b-tab title="EP 이미지 관리">
        <ep-image></ep-image>
      </b-tab>
      <b-tab title="추가속성 관리">
        <ep-props v-if="loaded[1]"></ep-props>
      </b-tab>
      <b-tab title="데이터 매칭 관리">
        <ep-data-matching v-if="loaded[2]"></ep-data-matching>
      </b-tab>
      <b-tab title="제외대상 상품">
        <ep-exclude-goods v-if="loaded[3]"></ep-exclude-goods>
      </b-tab>
      <b-tab title="제외대상 브랜드">
        <ep-exclude-brands v-if="loaded[4]"></ep-exclude-brands>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import EpImage from './EpImage.vue'
import EpExcludeGoods from './EpExcludeGoods.vue'
import EpExcludeBrands from './EpExcludeBrands.vue'
import EpProps from './EpProps.vue'
import EpDataMatching from './EpDataMatching.vue'

export default {
  name: 'NaverEP',
  title: 'Naver EP 관리',
  components: {EpImage, EpProps, EpExcludeGoods, EpExcludeBrands, EpDataMatching},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
