<template>
  <div>
    <b-alert variant="danger" show>개발중인 화면입니다.</b-alert>
    <div>현재 제외대상 상품 : {{items.list.length}} 개</div>
    <htb ref="htb" v-model="items.list" :config="htbConfig"></htb>
    <hr/>
    <b-row>
      <b-col>
        <div>제외대상 추가(발란코드)</div>
        <b-textarea v-model="addNos" rows="10"></b-textarea>
        <b-btn class="mt-1" variant="success" @click="add()" :disabled="busy.add">추가<b-spinner class="ml-1" small v-if="busy.add"></b-spinner></b-btn>
      </b-col>
      <b-col>
        <div>제외대상에서 제거(발란코드)</div>
        <b-textarea v-model="removeNos" rows="10"></b-textarea>
        <b-btn class="mt-1" variant="danger" @click="remove()" :disabled="busy.remove">제거<b-spinner class="ml-1" small v-if="busy.remove"></b-spinner></b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'EpExcludeGoods',
  title: 'EP 제외대상 상품 관리',
  data() {
    return {
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, listmore: false, add: false, remove: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      addNos: '',
      removeNos: '',

      htbConfig: {
        settings: {
          contextMenu: true,
          filters: true,
          dropdownMenu: true,
        }
      }
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      await this.$api.postTable(this, '/goods/ep/excludeGoods', {});
      this.render();
    },
    async add() {
      const nos = this.addNos ? this.addNos.trim().split(/\D+/).filter(e => e !== '').map(e => +e) : [];
      if (nos.length === 0) return alert('추가할 발란코드를 입력해주세요');

      this.busy.add = true;
      const j = await this.$api.postJson('/goods/ep/manageExcludeGoods', {type: 'add', nos});
      this.busy.add = false;
      if (j) {
        this.addNos = '';
        this.$utils.alert('추가되었습니다.');
        this.list();
      }
    },
    async remove() {
      const nos = this.removeNos ? this.removeNos.trim().split(/\D+/).filter(e => e !== '').map(e => +e) : [];
      if (nos.length === 0) return alert('제거할 발란코드를 입력해주세요');

      this.busy.remove = true;
      const j = await this.$api.postJson('/goods/ep/manageExcludeGoods', {type: 'remove', nos});
      this.busy.remove = false;
      if (j) {
        this.removeNos = '';
        this.$utils.alert('제거되었습니다.');
        this.list();
      }
    },
    render() {
      setTimeout(() => this.$refs.htb.hotInstance.render(), 0);
    },
  }
}
</script>
