<template>
  <div>
    <b-alert variant="danger" show>
      발란 최종 카테고리와 네이버 카테고리의 매칭 정보를 조회할 수 있는 화면입니다. 카테고리 매칭의 변경이 필요한 경우 개발팀으로 업데이트 요청해주세요.
    </b-alert>

    <b-input-group class="mb-2">
      <b-input-group-prepend>
        <b-button variant="primary" @click="search()" :disabled="busy.search">
          <i class="fa fa-search"></i> 검색
          <b-spinner class="mr-1" small v-if="busy.search"></b-spinner>
        </b-button>
      </b-input-group-prepend>
      <b-form-input id="search" type="text" placeholder="검색어를 입력해주세요"
        v-model="searchStr" @keypress.enter="search()" autocomplete="off" v-focus>
      </b-form-input>
    </b-input-group>

    <p>
      <category-preset class="" v-model="form.category" v-bind="{ hideOptions: true, finalCate: 'only' }"></category-preset>
      <b-btn variant="primary" @click="search()">검색</b-btn>
    </p>

    <v-client-table :columns="Object.keys(columns)"
                    :data="mappingsFiltered"
                    :options="tableOptions"
                    :theme="tableTheme"
                    id="dataTable">
    </v-client-table>
  </div>
</template>

<script>
  import Vue from 'vue';
  // https://matanya.gitbook.io/vue-tables-2
  import { ClientTable, Event } from 'vue-tables-2';

  const COLUMNS = {
    'categoryCode': { name: '발란 카테고리 코드' },
    'balaanCategoryName': { name: '발란 카테고리 명' },
    'balaanCategoryPath': { name: '발란 카테고리 경로' },
    'naverCategoryId': { name: '네이버 카테고리 코드' },
    'naverCategoryName': { name: '네이버 카테고리 경로' },
  };

  Vue.use(ClientTable, {}, false, 'bootstrap4');

  export default {
    name: 'EpCategoryMatching',
    title: 'EP 카테고리 매핑',
    components: { ClientTable, Event },
    data() {
      return {
        columns: COLUMNS,

        busy: { search: false, list: false },
        hasMore: { list: false },

        perPage: 10,

        form: { category: [] },
        searchStr: '',

        mappings: [],
        mappingsFiltered: [],

        tableTheme: 'bootstrap4',
        tableOptions: {
          headings: Object.fromEntries(
            Object.entries(COLUMNS).map(([key, value]) => [key, value.name]),
          ),
          sortable: Object.keys(COLUMNS),
          filterable: false,
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          alwaysShowPerPageSelect: true,
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'scroll',
          },
        },
        // hotSettings: {
        //   colHeaders: Object.values(COLUMNS).map(col => col.name),
        //   width: '100%',
        //   manualColumnResize: true,
        //   stretchH: 'all',
        //   height: '100%',
        //   licenseKey: 'non-commercial-and-evaluation',
        //   autoWrapRow: true,
        //   autoWrapCol: true,
        //   dropdownMenu: true,
        //   contextMenu: true,
        //   filters: true,
        // },
      };
    },
    mounted() {
      this.search();
    },
    methods: {
      async fetchData() {
        const balaanCategoryCodes = this.form.category ? this.form.category.map(e => e.value) : [];
 
        console.log(this.form.category);
 
        const resp = await this.$api.postJson('/goods/ep/categoryMatching/list', {
          balaanCategoryCodes,
          onlyFinal: true,
        });
        const { ok, list } = resp;
 
        if (!ok) {
          console.error(resp);
          return;
        }
 
        this.mappings = list;
      },
 
      async search() {
        this.busy.search = true;
 
        await this.fetchData();
 
        this.mappingsFiltered = this.mappings
          .filter(mapping => {
            if (!this.searchStr) return true;
            return Object.values(mapping).some(value => String(value).includes(this.searchStr));
          })
          .sort((a, b) => a.categoryCode < b.categoryCode ? -1 : 1);
 
        this.busy.search = false;
      },
    },
  };
</script>
